.dashcard-container {
    border-radius: var(--border-border-radius-border-radius-12, 12px);
    border: 1px solid #b8b8b0;
    background: linear-gradient(
        180deg,
        rgba(214, 212, 205, 0.15) 0%,
        rgba(254, 254, 254, 0.03) 27.5%,
        rgba(250, 249, 249, 0.15) 100%
    );
    box-shadow: 0px 1px 0px 0px #fff;
    backdrop-filter: blur(8px);
    margin: 0 16px;
    transform: translateY(48px);
    height: 166px;
    @media (max-width: 350px) {
        margin: 0 8px;
    }
}

.bottom-text {
    width: 90%;
    margin: 0 auto 21px;
    padding-top: 0 !important;
}

.top-text {
    width: 90%;
    margin: 35px auto 8px;
}
