@use '../../../styles/colors' as *;
@use '../../../styles/components' as *;
@use '../../../styles/typography' as *;

@keyframes gray-gradient-hover {
    0% {
        opacity: 0;
        background-image: none;
    }

    25% {
        opacity: 0.25;
        background-image: conic-gradient(
            from 180deg,
            rgba(70, 70, 70, 0.1) 0deg,
            rgba(80, 80, 80, 0.3) 90deg, 
            rgba(90, 90, 90, 0.2) 180deg,
            rgba(80, 80, 80, 0.3) 270deg, 
            rgba(70, 70, 70, 0.1) 360deg
        );
    }

    50% {
        opacity: 0.5;
        background-image: conic-gradient(
            from 180deg,
            rgba(80, 80, 80, 0.2) 0deg,
            rgba(90, 90, 90, 0.4) 90deg, 
            rgba(100, 100, 100, 0.3) 180deg, 
            rgba(90, 90, 90, 0.4) 270deg, 
            rgba(80, 80, 80, 0.2) 360deg
        );
    }

    75% {
        opacity: 0.75;
        background-image: conic-gradient(
            from 180deg,
            rgba(90, 90, 90, 0.3) 0deg, 
            rgba(100, 100, 100, 0.5) 90deg, 
            rgba(120, 120, 120, 0.4) 180deg, 
            rgba(100, 100, 100, 0.5) 270deg, 
            rgba(90, 90, 90, 0.3) 360deg
        );
    }

    100% {
        opacity: 1;
        background-image: conic-gradient(
            from 180deg,
            #464646 0deg, 
            #545454 90deg, 
            #737373 180deg, 
            #545454 270deg, 
            #464646 360deg
        );
    }
}


.gradient-circle-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;

    .gradient-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        clip-path: circle(50% at 50% 50%);

        .background-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }

    .child-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    
        .menu-icon  {
            width: 24px;
            height: 24px;
            transform: none;
            z-index: 1;
        }
    
        .dashboard-icon,
        .view-list-icon {
            transition: opacity 0.5s ease;
        }
    }
    
    &.colored {
        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 13px);
            left: calc(50% - 13px);
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background: conic-gradient(
            from 51.21deg at 29.32% 55.21%,
                #225CAF 0deg,
                #AE342D 65.89deg,
                #B28013 130.46deg,
                #BC5746 186.97deg,
                #CC1896 254.81deg,
                #869E1A 325.35deg,
                #225CAF 360deg
            );
            filter: blur(6px);
            transition: transform 0.5s ease;
        }

        .gradient-circle {
            position: relative;
            z-index: 1;
            background: conic-gradient(
                from 51.21deg at 29.32% 55.21%,
                #225CAF 0deg,
                #AE342D 65.89deg,
                #B28013 130.46deg,
                #BC5746 186.97deg,
                #CC1896 254.81deg,
                #869E1A 325.35deg,
                #225CAF 360deg
            );
            clip-path: circle(50% at 50% 50%);
            transition: transform 0.5s ease;
            will-change: transform;
        }

        &:hover {
            &::after {
                transform: rotate(-180deg);
            }

            .gradient-circle {
                transform: rotate(-180deg);
            }

            .tooltip-wrapper {
                transform: translateX(20px) translateY(-50%);
                opacity: 1;

                &.top {
                    transform: translateX(-100%) translateY(-55px);
                }
            }
        }

        .background-circle {
            background: $eth-black-light;
        }

        &.inactive {
            cursor: default !important;
            .gradient-circle {
                background: $eth-black-light;
            }

            &::after, &::before {
                display: none;
            }
        }
    }

    &.gray {
        border-radius: 50%;
        opacity: 0.8;
        cursor: pointer;

        .gradient-circle {
            background-color: #e4e4e4;
            background-image: none;
            .background-circle {
                background-color: #d2d2d2;
                box-shadow: 0px 1px 1px 0px #e4e4e4 inset;
                backdrop-filter: blur(60px);
            }
        }

        .child-wrapper {
            .dashboard-icon path,
            .view-list-icon path {
                fill: $eth-black-light;
            }
        }

        &.dashboard-active{
            &:hover {
                opacity: 1;    
                .tooltip-wrapper {
                    transform: translateX(20px) translateY(-50%);
                    opacity: 1;
                }
            }
        }

        &.inactive {
            background-color: transparent;
            box-shadow: none;
    
            .gradient-circle {
                background-image: none;
                background-color: transparent;
                opacity: 0;
    
                .background-circle {
                    background-color: transparent;
                    box-shadow: none;
                    backdrop-filter: unset;
                }
            }
    
            .child-wrapper {
                .dashboard-icon,
                .view-list-icon {
                    opacity: 0.6;
    
                    & path {
                        fill: $eth-white;
                    }
                }
            }
    
            &:hover {
                opacity: 1;
                .gradient-circle {
                    background-color: transparent;
                    background-image: conic-gradient(
                        from 180deg,
                        #464646 0deg, 
                        #545454 90deg, 
                        #737373 180deg, 
                        #545454 270deg, 
                        #464646 360deg
                    );
                    opacity: 1;
    
                    animation: gray-gradient-hover 0.4s ease;
                }
    
                .background-circle {
                    background: #2C2C2C;
                    box-shadow: inset 0 5px 10px rgba(96, 96, 96, 0.8);
                }
    
                .child-wrapper {
                    .dashboard-icon,
                    .view-list-icon {
                        opacity: 1;
                    }
                }
    
                .tooltip-wrapper {
                    transform: translateX(20px) translateY(-50%);
                    opacity: 1;
                }
            }
        }
    }

    &.gradient-shadow::before {
        content: "";
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        border-radius: 50%;
        background: conic-gradient(
                from 51.21deg at 29.32% 55.21%,
                #225CAF 0deg,
                #AE342D 65.89deg,
                #B28013 130.46deg,
                #BC5746 186.97deg,
                #CC1896 254.81deg,
                #869E1A 325.35deg,
                #225CAF 360deg
        );
        z-index: 0;
        opacity: 0.5;
        filter: blur(14px);
    }
}
