@use '../../styles/colors' as *;

.features-section {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.features {
    position: absolute;
    background-color: #eeedea;
    top: 0;
    left: 0;
    width: 120%;
    height: 100vh;
    z-index: -1;
}

.innovation {
    background: url('../../assets/backgrounds/innovation.png');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
}
