@use '../../../../styles/colors.scss' as *;

.innovation-container {
    height: 100vh;
}

.innovation-animation {
    display: none;
    height: 100vh;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
}

.innovation-elements {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.innovation-title {
    font-size: 50px;
    line-height: 1.2;
    @media (max-width: 1300px) {
        font-size: 36px;
    }
}

.innovation-span {
    white-space: nowrap;
    padding: 0 5px;
    margin-left: -5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.innovation-desc {
    max-width: 476px;
    font-size: 20px;
    @media (max-width: 1400px) {
        max-width: 320px;
        font-size: 14px;
    }
}

.innovation-bird {
    margin-top: 80px;
    @media (max-width: 1300px) {
        margin-top: 0px;
    }
}

.innovation-bird-svg {
    @media (max-width: 1300px) {
        width: 200px;
        height: fit-content;
    }
}

// Mobile version
.innovation-animation-mob {
    display: none;
    height: 100vh;
    overflow: hidden;
}

.innovation-elements-mob {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 90%;
    margin: 52px auto;
}

.innovation-title-mob {
    font-size: 32px;
}

.innovation-span-mob {
    white-space: nowrap;
    padding: 0 5px;
    margin-left: 5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.innovation-desc-mob {
    margin-top: 16px;
    max-width: 476px;
    font-size: 16px;
    line-height: 24px;
}

.innovation-bird-mob {
    margin: 80px auto 0;
    transform: translateX(-10px);
}
