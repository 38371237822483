@use '../../../styles/spacing' as *;
@use '../../../styles/colors' as *;

.message {
    padding: $space-curve-hecto;
    gap: 18px;
    border-radius: 12px;
    margin-bottom: $space-curve-hecto;
    @media (max-width: 450px) {
        gap: 8px;
        padding: 16px;
        margin-bottom: 12px;
    }
    &__user-icon {
        color: $eth-white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 130%;
        background-color: var(--surface-surface-theme-blue, #215caf);
        border-radius: 256px;
        box-shadow: 0px 2px 1px 0px #fff;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        padding-top: 5px;
        @media (max-width: 450px) {
            min-width: 24px;
            width: 24px;
            height: 24px;
            font-size: 12px;
            line-height: 100%;
        }
    }

    &__user {
        margin-right: 92px;
        border: 1px solid rgba(171, 167, 153, 0.8);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 94.64%, #fff 100%), #f0ede5;
        box-shadow: 0 3px 4px 0 rgba(136, 126, 100, 0.2);
        @media (max-width: 450px) {
            margin-right: 0;
            margin-left: 20px;
        }

        p, div {
            word-break: break-word;
        }

        &:hover.editable {
            border-radius: 12px;
            border: 1px solid #ABA799;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 94.64%, #FFF 100%), #F7F6F2;
        }
    }

    &__reply {
        margin-left: 92px;
        border: 2px solid #fff;
        background: rgba(245, 244, 243, 0.9);
        box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.3);
        @media (max-width: 450px) {
            margin-left: 0;
            margin-right: 20px;
        }
    }

    p {
        margin-bottom: 0;
    }

    &__controls {
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        opacity: 0;
        transform: translateY(10px);
        transition: transform 0.5s ease;
        height: 0;
    }

    &:hover {
        .message__controls {
            display: flex;
            opacity: 1;
            transform: translateY(0);
            height: 21px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        .button-secondary {
            background-color: transparent;
        }

        .check-icon {
            width: 24px;
            height: 16px;
            margin-top: -8px;
        }
    }

    .text-input {
        background: transparent;
        border: none;
        resize: none;
        overflow: hidden;
        outline: none;
    }

}

.desktop-message-icon {
    display: block;
    @media (max-width: 450px) {
        display: none;
    }
}
.mob-message-icon {
    display: none;
    width: 24px;
    height: 24px;
    @media (max-width: 450px) {
        display: block;
    }
}
