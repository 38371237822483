@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;

.left-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $eth-black-light;
    color: $eth-white;
    z-index: 4;

    .segment-container {
        position: absolute;
        top: 0px;
        left: 272px;
        width: 4px;
        height: 4px;
        transition: left 0.3s;

        .square {
            position: relative;
            width: 4px;
            height: 4px;
            overflow: hidden;
            @media (max-width: 450px) {
                display: none;
            }

            .segment {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $eth-black-light;

                clip-path: polygon(0% 0%, 0% 50%, 6% 31%, 13% 20%, 20% 13%, 26% 9%, 39% 2%, 50% 0%, 0% 0%);
            }
        }

        &.bottom {
            top: auto;
            bottom: 0px;

            .square {
                .segment {
                    transform: rotate(270deg);
                }
                @media (max-width: 450px) {
                    display: none;
                }
            }
        }
    }

    .menu-content {
        flex-grow: 1;
        padding: 32px 21px 32px 9px;
        max-height: 97vh;
        overflow: hidden;

        .menu-title {
            display: flex;
            justify-content: space-between;

            .menu-title-icons {
                display: flex;
                gap: 8px;

                .close-left-menu-icon {
                    cursor: pointer;
                }
            }
        }

        .session-list-container {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            scrollbar-width: none;

            &__day-label {
                line-height: 150%;
                padding-top: $space-curve-hecto;
            }
        }

        .session-list-item-wrapper {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            padding-top: 13px;

            .session-list-item {
                position: relative;
                transition: padding 0.6s ease, border-color 0.6s ease;
                border-left: 3px solid transparent;

                &__status {
                    margin-left: auto;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                &:hover {
                    padding-left: $space-curve-micro;
                    border-left-color: #fff;

                    .session-list-item__title::after {
                        transition: opacity 0.6s ease;
                        opacity: 1; // Gradually hide the fade-out effect on hover
                    }
                }

                &__title {
                    position: relative;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 50px;
                        background: linear-gradient(to left, rgba(34, 34, 34, 1), rgba(34, 34, 34, 0));
                        pointer-events: none;
                        opacity: 0;
                    }
                }
            }

            .session-content {
                transition: transform 0.6s ease;
            }
        }
    }
}
