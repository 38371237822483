@use '../../../styles/spacing' as *;
@use '../../../styles/colors' as *;

.session-rate {
    margin-top: $space-curve-hecto;
    border-radius: 12px;
    border: 2px solid #FFF;
    background: rgba(245, 244, 243, 0.90);
    box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.50) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.30);
    padding: $space-curve-hecto;
    margin-bottom: 24px;

    & > div {
         gap: 18px;
    }

    &__icons {
        gap: 12px;

        .rate-icon {
            border-radius: 36px;
            border: 2px solid #FFF;
            opacity: 0.8;
            background: #E0E0E0;
            box-shadow: 0px 3px 1px 0px #FFF inset, 0px 3px 4px 0px #C6C3BB;
            backdrop-filter: blur(30px);
            width: 36px;
            height: 36px;
            padding: 6.5px 6px 5.5px 5px;

            &.like {
                padding: 4px 6px 5.5px 5px;
            }
        }

        .rate-icon.active, .rate-icon:hover {
            border-radius: 30px;
            border: 1px solid #000;
            background: var(--surface-surface-primary, #222);
            box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;

            & path {
                fill: $eth-white;
            }
        }
    }

    &__comment {
        margin-top: 12px;

        &--buttons {
            gap: 8px;
            right: 0;
            margin-top: 12px;
        }

        &--input {
            height: 37px;
            min-width: 312px;
            min-height: 37px;
            padding: $space-fixed-nano $space-fixed-micro;
            border-radius: 2px;
            border: 1px solid $eth-grey-80;
            background: $eth-white;
            margin-top: 6px;
        }
    }

    &__success {
        gap: 10px;
        padding: 16px;
        border-radius: 8px;
        background: $eth-black-light
    }
}
