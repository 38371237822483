.header {
    width: fit-content;
    padding: 1.5rem;
    &__mob {
        display: flex !important;
        @media (max-width: 450px) {
            display: none !important;
        }
    }
}
