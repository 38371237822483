@use '../../styles/colors' as *;

.menu-control {
    position: absolute;
    top: calc(50% - 117px);
    right: -54px;
    padding: 47px 0;
    width: 54px;
    height: 234px;
    background-image: url('../../assets/img/menu-control-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 450px) {
        display: none;
    }

    .menu-control-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding-right: 6px;
    }
}
