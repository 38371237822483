@use '../../../styles/colors' as *;
@use '../../../styles/components' as *;
@use '../../../styles/typography' as *;

@keyframes gray-gradient-hover {
    0% {
        opacity: 0;
        background-image: none;
    }

    25% {
        opacity: 0.25;
        background-image: conic-gradient(
            from 180deg,
            rgba(70, 70, 70, 0.1) 0deg,
            rgba(80, 80, 80, 0.3) 90deg,
            rgba(90, 90, 90, 0.2) 180deg,
            rgba(80, 80, 80, 0.3) 270deg,
            rgba(70, 70, 70, 0.1) 360deg
        );
    }

    50% {
        opacity: 0.5;
        background-image: conic-gradient(
            from 180deg,
            rgba(80, 80, 80, 0.2) 0deg,
            rgba(90, 90, 90, 0.4) 90deg,
            rgba(100, 100, 100, 0.3) 180deg,
            rgba(90, 90, 90, 0.4) 270deg,
            rgba(80, 80, 80, 0.2) 360deg
        );
    }

    75% {
        opacity: 0.75;
        background-image: conic-gradient(
            from 180deg,
            rgba(90, 90, 90, 0.3) 0deg,
            rgba(100, 100, 100, 0.5) 90deg,
            rgba(120, 120, 120, 0.4) 180deg,
            rgba(100, 100, 100, 0.5) 270deg,
            rgba(90, 90, 90, 0.3) 360deg
        );
    }

    100% {
        opacity: 1;
        background-image: conic-gradient(
            from 180deg,
            #464646 0deg,
            #545454 90deg,
            #737373 180deg,
            #545454 270deg,
            #464646 360deg
        );
    }
}

.session-button-circle-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 51px;
    margin: 0 auto 10px;

    .session-button-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 51px;
        padding: 2px;

        .background-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 2;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 2px;
            background: conic-gradient(
                from 52deg at 70.32% 24.21%,
                #225caf 4%,
                #ae342d 17%,
                #ae342d 53%,
                #b28013 58.3%,
                #bc5746 59%,
                #cc1896 62%,
                #869e1a 64%,
                #225caf 106%
            );
        }

        .gradient-circle {
            position: relative;
            z-index: 1;
            background: conic-gradient(
                from 51.21deg at 29.32% 55.21%,
                #225caf 0deg,
                #ae342d 65.89deg,
                #b28013 130.46deg,
                #bc5746 186.97deg,
                #cc1896 254.81deg,
                #869e1a 325.35deg,
                #225caf 360deg
            );
            clip-path: circle(50% at 50% 50%);
            transition: transform 0.5s ease;
            will-change: transform;
        }

        &:hover {
            &::after {
                transform: rotate(-180deg);
            }

            .gradient-circle {
                transform: rotate(-180deg);
            }

            .tooltip-wrapper {
                transform: translateX(20px) translateY(-50%);
                opacity: 1;
            }
        }

        .background-circle {
            background: $eth-black-light;
        }
    }

    &.gray {
        border-radius: 50%;
        opacity: 0.8;
        cursor: pointer;

        .gradient-circle {
            background-color: #e4e4e4;
            background-image: none;
            .background-circle {
                background-color: #d2d2d2;
                box-shadow: 0px 1px 1px 0px #e4e4e4 inset;
                backdrop-filter: blur(60px);
            }
        }

        .session-child-button-wrapper {
            .dashboard-icon path,
            .view-list-icon path {
                fill: $eth-black-light;
            }
        }
    }

    &.inactive {
        background-color: transparent;
        box-shadow: none;

        .gradient-circle {
            background-image: none;
            background-color: transparent;
            opacity: 0;

            .background-circle {
                background-color: transparent;
                box-shadow: none;
                backdrop-filter: unset;
            }
        }

        .child-wrapper {
            .dashboard-icon,
            .view-list-icon {
                opacity: 0.6;

                & path {
                    fill: $eth-white;
                }
            }
        }

        &:hover {
            opacity: 1;
            .gradient-circle {
                background-color: transparent;
                background-image: conic-gradient(
                    from 180deg,
                    #464646 0deg,
                    #545454 90deg,
                    #737373 180deg,
                    #545454 270deg,
                    #464646 360deg
                );
                opacity: 1;

                animation: gray-gradient-hover 0.4s ease;
            }

            .background-circle {
                background: #2c2c2c;
                box-shadow: inset 0 5px 10px rgba(96, 96, 96, 0.8);
            }

            .child-wrapper {
                .dashboard-icon,
                .view-list-icon {
                    opacity: 1;
                }
            }

            .tooltip-wrapper {
                transform: translateX(20px) translateY(-50%);
                opacity: 1;
            }
        }
    }

    &.gradient-shadow::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        border-radius: 50%;
        background: conic-gradient(
            from 51.21deg at 29.32% 55.21%,
            #225caf 0deg,
            #ae342d 65.89deg,
            #b28013 130.46deg,
            #bc5746 186.97deg,
            #cc1896 254.81deg,
            #869e1a 325.35deg,
            #225caf 360deg
        );
        z-index: 0;
        opacity: 0.5;
        filter: blur(14px);
    }
}
