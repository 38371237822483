.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modalContent {
    display: flex;
    width: 90%;
    padding: 20px;
    max-width: 764px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    border-radius: 12px;
    border: 2px solid #fff;
    background: rgba(245, 244, 243, 0.9);
    box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.3);
    backdrop-filter: blur(4px);
}

.error-message {
    color: red;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modal-what {
    color: #000;
    font-family: 'DIN Next W1G';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 0;
}

.line-seperator {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background: rgba(0, 0, 0, 0.6);
}

.scale-span {
    color: black;
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.body-heading {
    max-width: 405px;
}

.closeButtons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.closeButtons kbd {
    border-radius: 4px;
    border: 1px solid;
    background: rgba(255, 255, 255, 0.2);
    color: #000;
    text-align: center;
    font-family: 'DIN Next W1G';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    height: 24px;
    width: 44px;
    opacity: 0.7;
    border-image-source: linear-gradient(90deg, #b8b8b8, #5f5f5f);
    border-image-slice: 1;
    border-image-repeat: stretch;
    border-radius: 4px;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
}

.upload-button {
    display: flex;
    padding: 14px 32px 13px 32px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid rgba(34, 34, 34, 0.2);
    background-color: white;
    font-weight: 500;
    cursor: pointer;
}

.upload-button:hover {
    border: 1px solid #000;
}

.modalBody {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #c5c5c5;
    background: #fff;
}

.feedbackType {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    opacity: 0.8;
    margin-top: 2px;
}

.feedback-label {
    display: flex;
    cursor: pointer;
}

.check-icon {
    margin-top: -4px;
    margin-right: 5px;
}

.feedback-lable-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;
}

.label-text {
    margin-left: 4px;
}

.label-follow {
    font-family: 'DIN Next W1G Light';
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
}

.ratingButtons {
    display: flex;
    gap: 15px;
    margin-top: 8px;
    margin-bottom: 4px;
    cursor: pointer;
}

.extremely-likely {
    margin-right: 32px;
}

.line-seperator-footer {
    margin: 9px 0;
}

.ratingButtons button {
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.activeOne {
    background-color: #000 !important;
    border: 2px solid #000 !important;
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset !important;
    color: #000 !important;
}

.activeRating:hover {
    background-color: #000 !important;
    border: 2px solid #000 !important;
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset !important;
    color: #000 !important;
}

.activeRating {
    position: relative;
    border-radius: 36px;
    border: 2px solid #fff;
    opacity: 0.8;
    background-color: #e0e0e0;
    box-shadow: 0px 3px 1px 0px #fff inset, 0px 3px 4px 0px #c6c3bb;
    backdrop-filter: blur(30px);
    width: 36px;
    height: 36px;
    flex-shrink: 0;
}

.number-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.ratingLabels {
    display: flex;
    justify-content: space-between;
    color: #6f6f6f;
    font-family: 'DIN Next W1G Light';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 20px;
    margin-bottom: 8px;
}

.thoughts-text {
    width: 100%;
    font-family: 'DIN Next W1G';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 6px;
}

.bug-description {
    font-family: 'DIN Next W1G';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margint-bottom: 6px;
}

.thoughts {
    width: 100%;
}

.bugDescription {
    width: 100%;
}

.empty-div {
    width: 20px;
    height: 10px;
}

.upload-text {
    font-family: 'DIN Next W1G';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 12px;
}

.thoughts textarea,
.bugDescription textarea {
    width: 100%;
    display: flex;
    min-width: 300px;
    min-height: 150px;
    padding: var(--space-fixed-micro, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid var(--border-border-colour-Form-Fields-border-default, #8c8c8c);
    background: var(--surface-surface-base, #fff);
}

.fileUpload input {
    margin-top: 8px;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px;
    background-color: #f9fafb;
    border-top: 1px solid #e5e5e5;
}

.cancelButton {
    background-color: white;
    border: 1px solid #d1d5db;
    padding: 14px 32px 13px 32px;
    border-radius: 2px;
    cursor: pointer;
}

.cancelButton:hover {
    border: 1px solid #000000;
}

.sendButton {
    background-color: #222222;
    color: white;
    border: none;
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    width: 190px;
    padding: 14px 32px 13px 32px;
    height: 55px;
    border-radius: 2px;
    cursor: pointer;
}

.sendButton:hover {
    background-color: #000000;
}

.followUp {
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.bottom-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
}

.error-msg {
    color: red;
    font-size: 14px;
}
