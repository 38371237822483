@use '../../../../styles/colors' as *;
@use '../../../../styles/spacing' as *;
@use '../../../../styles/typography' as *;

.footer {
    background-color: $eth-black-light;
    color: $eth-white;
    padding: 0 $space-curve-tera;
    overflow-x: hidden;
    @media (max-width: 450px) {
        padding: 0 16px;
    }

    .footerContainer {
        max-width: 1360px;
        margin: 0 auto;
        padding: 0 $space-curve-nano;
    }

    .footerMain {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: $space-curve-centi;
        margin-bottom: $space-curve-giga;
        margin-top: 60px;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
            gap: $space-curve-nano;
        }
    }

    .footerSection {
        grid-column: span 3;
        .footerSectionTitle {
            font-size: 16px;
            font-weight: 600;
        }
        @media (max-width: 450px) {
            width: 100%;
            grid-column: span 7;
        }
    }

    .footer-logo {
        margin-bottom: $space-curve-micro;
        width: 140px;
    }

    .footerMidSection {
        grid-column: span 7;
        .footerSectionTitle {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: $space-curve-micro;
        }
        @media (max-width: 450px) {
            margin-bottom: 26px;
        }
    }

    .features-container {
        text-align: center;
        font-size: $font-size-h2;
    }

    .footerEndSection {
        grid-column: span 2;
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-right: $space-curve-kilo;
        .footerSectionTitle {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: $space-curve-mili;
        }
        @media (max-width: 450px) {
            align-items: start;
        }
    }

    .footerLinks {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 40px;
        li {
            grid-column: span 1 / span 1;
            margin-bottom: 12px;
            width: max-content;
            @media (max-width: 450px) {
                grid-column: span 2 / span 2;
            }
        }

        li > a {
            color: $eth-white;
            text-decoration: none;
            font-size: 16px;
            opacity: 0.8;
            transition: opacity 0.2s ease;
            margin-right: 1px;
            text-decoration: underline;
            text-underline-offset: 2px;

            &:hover {
                opacity: 1;
            }
        }
    }

    .footer-seperator {
        background-color: rgba(255, 255, 255, 0.1);
        height: 1px;
        width: 100%;
    }

    .footer-seperator-black {
        background-color: rgba(0, 0, 0, 0.4);
        height: 1px;
        width: 100%;
    }

    .footerBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: $eth-grey-40;
        margin-bottom: 30px;
        margin-top: 30px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: $space-curve-micro;
        }
    }

    .copyrights {
        margin-bottom: 20px;
    }

    .footerLegal {
        display: flex;
        gap: $space-curve-nano;

        @media (max-width: 768px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: $space-curve-nano;
        }
        @media (max-width: 450px) {
            grid-template-columns: repeat(2, 1fr);
            flex-wrap: no-wrap;
            justify-content: start;
            display: none;
        }

        a {
            color: $eth-white;
            text-decoration: none;
            opacity: 0.8;
            transition: opacity 0.2s ease;

            &:hover {
                opacity: 1;
            }
        }
    }

    .feedbackQuestion {
        color: $eth-grey-40;
    }

    .footerEmail {
        display: inline-block;
        padding: $space-fixed-micro $space-fixed-centi;
        border: 1px solid $eth-white;
        border-radius: 2px;
        color: $eth-white;
        text-decoration: none;
        font-size: 16px;
        transition: all 0.2s ease;
        width: 100%;
        max-width: 220px;
        text-align: center;

        &:hover {
            background-color: $eth-white;
            color: $eth-black;
        }

        @media (max-width: 450px) {
            max-width: 100%;
            margin-bottom: 40px;
        }
    }

    .footerSocial {
        display: flex;
        gap: $space-curve-micro;
        margin-right: 8px;

        a {
            color: $eth-white;
            opacity: 1;
            transition: opacity 0.2s ease;
        }
    }
}

.footerLegal-mob-top {
    display: none;
    justify-content: start;
    gap: 10px;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    @media (max-width: 450px) {
        display: flex;
    }
    a {
        color: $eth-white;
        text-decoration: none;
        opacity: 0.8;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }
    }
}

.footerLegal-mob-bottom {
    display: none;
    justify-content: start;
    gap: 10px;
    align-items: center;
    width: 100%;
    @media (max-width: 450px) {
        display: flex;
    }
    a {
        color: $eth-white;
        text-decoration: none;
        opacity: 0.8;
        transition: opacity 0.2s ease;

        &:hover {
            opacity: 1;
        }
    }
}
