$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 700;

// Desktop typography
$font-size-heading: 4rem;
$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-lead: 1.5rem;
$font-size-p1: 1.25rem;
$font-size-p2: 1.125rem;
$font-size-p3: 1rem;
$font-line-height-h1: 3rem;
$font-size-c: 0.875rem;
$font-size-l1: 1.25rem;
$font-size-l2: 1.125rem;
$font-size-l3: 1rem;
$font-line-height-h2: 2.375rem;
$font-line-height-h3: 1.9375rem;
$font-line-height-h4: 1.625rem;
$font-line-height-h5: 1.4375rem;
$font-line-height-lead: 2.0625rem;
$font-line-height-p1: 1.875rem;
$font-line-height-p2: 1.6875rem;
$font-line-height-p3: 1.5rem;
$font-line-height-c: 1.3125rem;
$font-line-height-l1: 1.5rem;
$font-line-height-l2: 1.3125rem;
$font-line-height-l3: 1.1875rem;
$font-size-h5: 1.125rem;

// Mobile typography
@media screen and (max-width: 768px) {
    $font-size-heading: 3.5rem;
    $font-size-h1: 2rem;
    $font-size-h2: 1.5rem;
    $font-size-h3: 1.25rem;
    $font-size-h4: 1.125rem;
    $font-size-lead: 1.25rem;
    $font-size-p1: 1.125rem;
    $font-size-p2: 1rem;
    $font-size-p3: 0.875rem;
    $font-line-height-h1: 2.375rem;
    $font-size-c: 0.75rem;
    $font-size-l1: 1.125rem;
    $font-size-l2: 1rem;
    $font-size-l3: 0.875rem;
    $font-line-height-h2: 1.9375rem;
    $font-line-height-h3: 1.625rem;
    $font-line-height-h4: 1.4375rem;
    $font-line-height-h5: 1.25rem;
    $font-line-height-lead: 1.875rem;
    $font-line-height-p1: 1.6875rem;
    $font-line-height-p2: 1.5rem;
    $font-line-height-p3: 1.3125rem;
    $font-line-height-c: 1.125rem;
    $font-line-height-l1: 1.3125rem;
    $font-line-height-l2: 1.1875rem;
    $font-line-height-l3: 1rem;
    $font-size-h5: 1rem;
}
