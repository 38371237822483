.footer-container {
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
    z-index: 10;
    @media (max-width: 450px) {
        display: none;
    }
}
