@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;
@use '../../styles/typography' as *;

.milestone-card-outer-wrapper {
    position: relative;
    width: 100%;
    border: 1px solid $eth-black;
    border-radius: $border-radius-12;
    background: $eth-black-light;
    box-shadow: 0px 2px 4px 0px #00000059;

    .milestone-card-shadow {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 24px;
        border-radius: $border-radius-12;
        box-shadow: 0px 2px 1px 0px #FFFFFF59 inset;
    }

    .milestone-card-inner-wrapper {
        position: relative;
        width: 100%;
        background: transparent;
        border-radius: $border-radius-12;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../assets/img/milestone-card-bg-shadow.svg') center top no-repeat;
            background-size: auto;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        .milestone-card-title-wrapper {
            position: relative;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .milestone-card-title-left-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;

                .milestone-card-icon {
                    width: 16px;
                    height: 16px;
                }

                .milestone-card-title {
                    font-weight: $font-weight-bold;
                    color: $eth-white;
                }
            }
            
            .milestone-card-title-right {
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                color: $eth-white;
            }
        }

        .milestone-card-content-wrapper {
            transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
            .milestone-card-content {
                padding: 16px;
                color: $eth-white;

                .milestone-card-content-item {
                    display: grid;
                    width: 100%;

                    .milestone-card-content-index-wrapper {
                        position: relative;
                        width: 26px;
                        height: 26px;
                        border-radius: 36px;
                        background: #E0E0E033;
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        box-shadow: 0px 1px 1px 0px #1F1F1F, 0px 1px 1px 0px #FFFFFF80 inset;

                        .milestone-card-content-index {
                            margin-top: 3px;
                            font-weight: $font-weight-bold;
                            line-height: 20.8px;
                            text-align: center;
                            color: $eth-white;
                        }
                    }

                    .milestone-card-content-text {
                        opacity: 0.9;
                    }
                }
            }
        }

        .milestone-card-separator-wrapper {
            transition: height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
        }

        &.close-card {
            .milestone-card-separator-wrapper,
            .milestone-card-content-wrapper {
                max-height: 0;
                opacity: 0;
            }
        }

        &.open-card {
            .milestone-card-separator-wrapper {
                height: 1px;
                width: 100%;
                padding: 0px 2px 0px 2px;
                border-top: 1px solid #00000066;
                border-bottom: 1px solid #FFFFFF1A;
            }
            .milestone-card-content-wrapper {
                position: relative;
                opacity: 1;
                max-height: 163px;
                overflow: auto;
                &::-webkit-scrollbar {
                    width: 0;
                }
            }
        }
    }

    &:hover {
        .milestone-card-inner-wrapper {
            &::before {
                opacity: 0.5;
            }

            .milestone-card-title-wrapper {
                .milestone-card-title-right {
                    opacity: 1;
                }
            }
        }
    }
}
