.help-hover {
    position: absolute;
    bottom: 56px;
    height: 57px;
    width: 58px;
    z-index: 5;
    @media (max-width: 450px) {
        display: none;
    }
}

.help-icons {
    position: absolute;
    left: 11px;
    bottom: 0;
    cursor: pointer;
}

.account-hover {
    position: absolute;
    bottom: 0;
    height: 57px;
    width: 60px;
    z-index: 6 !important;
    @media (max-width: 450px) {
        display: none;
    }
}

.help-icons-container {
    position: relative;
}

.help-icons-dark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s opacity ease-in-out;
}
