@use '../../styles/colors' as *;
@use '../../styles/typography' as *;


.eth-text {
    color: $eth-black;
}

.text-eth-error {
    color: $eth-alert-red;
}

.text-eth-green {
    color: $eth-green;
}

.text-eth-white {
    color: $eth-white;
}

.text-eth-back-50 {
    color: $eth-black-light-31;
}

.text-eth-back-80 {
    color: $eth-black-light-80;
}

.text-xs {
    font-size: $font-size-c;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.text-sm {
    font-size: $font-size-p3;
}

.text-md {
    font-size: $font-size-p2;
}

.text-xl {
    font-size: $font-size-p3;
    line-height: 150%;
    font-weight: 400;
}

.text-xxl {
    font-size: $font-size-p2;
}

.text-xxxl {
    font-size: $font-size-p1;
}

.text-h1 {
    font-size: $font-size-h1;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 0;
    font-family: DIN Next W1G;
}

.text-h4 {
    font-size: $font-size-h4;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    font-family: DIN Next W1G Bold;
}

.text-h5 {
    font-size: $font-size-p3;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    font-family: DIN Next W1G Bold;
}
