@use '../../styles/spacing' as *;
@use '../../styles/colors' as *;

.origami-bird-bg {
    background-image: url('../../assets/img/origami-bird-bg.svg');
    top: $space-curve-kilo;
    left: 14%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 548px;
    height: 298px;
    z-index: 1;
    @media (max-width: 450px) {
        display: none;
    }
}

.dashboard-wrapper {
    padding-top: 187px;
    min-height: calc(100vh - 77px);
    @media (max-width: 450px) {
        min-height: 0;
        padding-right: 16px;
        padding-left: 16px;
    }

    .container {
        max-width: 982px !important;
        padding: 0 !important;
    }

    @media screen and (max-width: 1700px) {
        padding-top: 100px;

        .container {
            max-width: 982px !important;
            padding: 0 !important;
        }
    }

    @media screen and (max-width: 1520px) {
        padding-top: 50px;
    }

    @media screen and (max-width: 1400px) {
        padding-top: 0;
    }

    @media screen and (max-width: 1280px) {
        padding-top: 50px;

        .container {
            max-width: 100% !important;
            padding: $space-curve-kilo 72px 0 72px !important;
        }
    }

    @media screen and (max-width: 450px) {
        .container {
            padding: 130px 0 0 !important;
        }
    }
}

.main-dashboard-wrapper {
    @media (max-width: 450px) {
        background-image: url('../../assets/backgrounds/mob-dash-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}

.mob-dash-bird {
    display: none;
    @media (max-width: 450px) {
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        display: block;
        left: 0;
        top: 40px;
        z-index: 2;
    }
}

.dashboard-text {
    opacity: 0.8;

    @media (max-width: 450px) {
        display: none;
    }
}

.dashboard-text-mob {
    color: #000;
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: none;

    @media (max-width: 450px) {
        display: block;
    }
}

.benefits-row {
    padding: 32px 16px 22px 16px;
    border-radius: var(--border-border-radius-border-radius-12, 12px);
    border: 1px solid #b8b8b0;
    background: linear-gradient(
        180deg,
        rgba(214, 212, 205, 0.15) 0%,
        rgba(254, 254, 254, 0.03) 27.5%,
        rgba(250, 249, 249, 0.15) 100%
    );
    box-shadow: 0 1px 0 0 #fff;
    backdrop-filter: blur(8px);
    margin-left: 0 !important;
    margin-right: 0 !important;

    @media (max-width: 450px) {
        display: none !important;
    }

    .col {
        padding: 0;
    }

    & .col:nth-child(-n + 2) {
        border-right: 1px solid #b8b8b0;
        padding-right: $space-curve-mili;
    }

    & .col:nth-child(n + 2) {
        border-left: 1px solid #ffffff;
        padding-left: $space-curve-mili;
    }
}

.benefits-icon-wrapper {
    padding: 8px;
    background: linear-gradient(180deg, $eth-grey-dark 0%, $eth-grey 100%), $eth-grey;
    backdrop-filter: blur(2px);
    border-radius: 50%;
    margin-right: 8px;
    margin-top: -12px;

    @media (max-width: 450px) {
        top: 4px !important;
        left: 18px;
        transform: translateY(-50%);
        height: 42px;
    }
}

.bird-icon {
    @media (max-width: 450px) {
        display: none;
    }
}

.eth-mob-icon {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%, 0);
}

.dashboard-container-mob {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-expect {
    display: block;
    @media (max-width: 450px) {
        display: none;
    }
}

.dashboard-mob-expect {
    font-size: 18px;
    padding: 0 16px;
    display: none;
    @media (max-width: 450px) {
        display: block;
    }
}

.dash-card-container {
    position: relative;
    width: 100%;
    max-height: 258px;
    height: 258px;
    display: none;
    @media (max-width: 450px) {
        display: flex;
    }
}

.dash-footer-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.swiper-card-dash {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dash-points {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

.mob-log-header {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0;
    display: none;
    z-index: 2;
    @media (max-width: 450px) {
        display: block;
    }
}

.subheading-dashboard {
    margin-top: 12px;
}
