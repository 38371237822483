@use '../../../styles/colors' as *;
@use '../../../styles/components' as *;
@use '../../../styles/typography' as *;

.milestone-setter-outer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: $border-radius-12;
    padding: 6px;
    border: 1px solid #000;
    background: var(--surface-surface-primary, #222);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
    @media (max-width: 450px) {
        border-radius: 12px 12px 0 0;
    }

    &::before {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        bottom: 7px;
        top: 7px;
        background: conic-gradient(from 69.21deg at 39.32% 55.21%,
            #225caf 0deg,
            #bc5746 33.89deg,
            #b28013 189.46deg,
            #cc1896 104.81deg,
            #869e1a 370.35deg
        );
        filter: blur(13px);
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;
    }

    .milestone-setter-wrapper {
        position: relative;
        background-color: #f7f5eef2;
        padding: 19px 19px 17.5px 25px;
        border-radius: 8px;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            background: url(../../../assets/img/stroke-bg.png);
            background-size: 108% 110%;
            background-position: center;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }

        .milestone-setter {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;

            @media (max-width: 450px) {
                flex-direction: column;
                gap: 8px;
            }

            &__left {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .milestone-setter__title {
                    margin-bottom: 4px;
                    font-size: 16px;
                    font-weight: $font-weight-bold;
                    color: #000;
                }

                .milestone-setter__line {
                    font-size: 16px;
                    font-weight: $font-weight-normal;
                    color: #000;
                    opacity: 0.8;
                    position: relative;

                    .line-bottom-shadow {
                        bottom: 0px;
                    }
                }

                .milestone-setter__input {
                    width: 100%;
                    max-height: 76px;
                    border: none;
                    background-color: transparent;
                    font-size: $font-size-p2;
                    font-weight: $font-weight-normal;
                    outline: none;
                    resize: none;
                    padding: 0;
                    font-size: 16px;

                    &::-webkit-scrollbar {
                        background: transparent;
                        width: 5px;
                    }

                    &::-webkit-scrollbar-button {
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 20px;
                        box-shadow: inset 0 0 10px 10px #00000050;
                        background: #222;
                        border: solid 3px transparent;
                    }
                }
            }
            &__right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;

                .button-secondary {
                    background-color: transparent;
                }

                .check-icon {
                    width: 24px;
                    height: 16px;
                    margin-top: -8px;
                }

                @media (max-width: 450px) {
                    justify-content: space-between;
                }
            }
        }
    }
}

.right-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.milestone-cancel-btn {
    min-width: 74px;
    border-radius: 2px;
}

.milestone-edit-btn {
    min-width: 56px;
    border-radius: 2px;
}

.milestone-save-btn {
    border-radius: 2px;
}

.milestone-edit-text {
    font-size: 14px;
    color: #000;
}

.milestone-cancel-text {
    font-size: 14px;
    color: #000;
}

.milestone-save-text {
    font-size: 14px;
}

.line-top-shadow {
    position: absolute;
    background: linear-gradient(0deg, rgba(2, 0, 36, 0) 0%, rgba(240, 236, 227, 1) 100%);
    width: 99%;
    height: 20px;
    top: 0;
    pointer-events: none;
}
.line-bottom-shadow {
    position: absolute;
    background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(240, 236, 227, 1) 100%);
    width: 99%;
    height: 20px;
    bottom: 8px;
    pointer-events: none;
}

.setter-text {
    max-height: 76px;
    overflow: auto;
    padding: 0;
}

.setter-text::-webkit-scrollbar {
    width: 5px;
}

.setter-text::-webkit-scrollbar-thumb {
    background: #222;
    border-radius: 10px;
}

.edit-box {
    margin-bottom: -8px;
    font-size: 16px;
    font-weight: $font-weight-normal;
    color: #000;
    opacity: 0.8;
    position: relative;
}
