@use '../../../styles/colors' as *;
@use '../../../styles/spacing' as *;

.mob-left-menu {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100dvh;
    background-color: $eth-black-light;
    color: $eth-white;
    z-index: 999;
    padding: 8px;
    transition-property: transform;
    transition: 0.5s ease-in-out;
    @media (max-width: 450px) {
        display: flex;
        height: 100dvh;
    }
}

.mob-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
}

.mob-logo-topbar {
    width: 96px;
    height: 38px;
}

.mob-bottombar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.mob-menu-items {
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: var(--surface-surface-primary, #222);
    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.09) inset;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: start;
    gap: var(--space-curve-hecto, 8px);
    align-self: stretch;
}

.mob-help-hover {
    display: none;
    position: absolute;
    bottom: 61px;
    left: -9px;
    height: 57px;
    width: 58px;
    @media (max-width: 450px) {
        display: block;
    }
}

.mob-acc-hover {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    @media (max-width: 450px) {
        display: block;
    }
}

.mob-account-details {
    border-radius: 100%;
    background-color: #215caf;
    border: 2px solid #1452aa;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 4px;
    cursor: pointer;
}

.mob-account-letter {
    font-size: 18px;
    font-family: 'DIN Next W1G';
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    height: 24px;
    cursor: pointer;
}

.sidebar-menu-button {
    font-size: 16px;
    font-family: 'DIN Next W1G';
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    height: 24px;
    cursor: pointer;
}

.menu-bars-dash {
    position: fixed;
    top: 18px;
    left: 8px;
    display: none;
    z-index: 5;
    @media (max-width: 450px) {
        top: 14px;
        display: block;
    }
}

.mob-session-btn {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.mob-session-menu {
    width: 100%;
    overflow: auto;
    max-height: 0;
    height: 66.5dvh;

    @media (max-height: 900px) {
        height: 62.5dvh;
    }
    @media (max-height: 820px) {
        height: 58.5dvh;
    }
    @media (max-height: 700px) {
        height: 52.5dvh;
    }
}

.mob-new-session {
    display: flex;
    padding: 14px 32px 13px 32px;
    justify-content: center;
    gap: 8px;
    width: 80%;
    align-items: center;
    width: 100%;
}

.mob-list-session {
    display: flex;
    padding: 14px 32px 13px 32px;
    justify-content: center;
    gap: 8px;
    width: 80%;
    align-items: center;
    width: 100%;
    text-align: center;
    font-weight: 400;
}

.session-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 0 !important;
}

.session-collapsed {
    max-height: 0;
    overflow: hidden;
    transition: 1s;
    transition-property: max-height;
}

.session-open {
    transition: 1s;
    transition-property: max-height;
    max-height: 1000px;
}

.session-open-empty {
    min-height: 0;
    max-height: 125px;
    transition: 1s;
    transition-property: max-height;
    height: 125px !important;
    overflow: hidden;
}

.sessions-list-box {
    position: relative;
    width: 100%;
}

.session-shadow-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(0, rgba(34, 34, 34, 0) 0%, #222 100%);
    pointer-events: none;
    z-index: 2;
}

.session-shadow-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222 100%);
    pointer-events: none;
    z-index: 2;
}

.mob-acc-button {
    height: 54px;
}

.session-card-bottom {
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    color: var(--text-text-color-text-inverted, #fff);
    text-overflow: ellipsis;

    /* 14-Regular */
    font-family: 'DIN Next W1G';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    opacity: 0.9;
}

.mob-session-list-title {
    margin-bottom: 0;
    overflow: hidden;
    color: var(--text-text-color-text-inverted, #fff);
    text-overflow: ellipsis;

    /* 16-Regular */
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.day-label {
    @media (max-width: 450px) {
        margin-bottom: 16px !important;
        margin-top: 7px !important;
    }
}

.menu-seperator {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 13px;
}
