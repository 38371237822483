@use '../../../styles/colors' as *;
.resource-overview {
    position: fixed;
    left: 50%;
    bottom: 10px;
    width: 95%;
    background-color: #222222;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 3px 1px -1px rgba(255, 255, 255, 0.35) inset,
        0px 1px 0 0px #000 inset;
    display: none;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 9;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: translateX(-50%);
    color: white;
    padding-top: 16px;
    padding-bottom: 16px;
    overflow: hidden;
    @media (max-width: 450px) {
        display: flex;
    }
}

.resource-overview-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    padding: 0 16px;
    margin-bottom: 16px;
}

.resource-overview-container {
    position: relative;
    width: 100%;
}

.resource-overview-body {
    overflow: auto;
    height: 252px;
    padding: 16px 16px 0 16px;
    width: 100%;

    &__number {
        position: relative;
        width: 26px;
        height: 26px;
    }
    &__ring {
        position: absolute;
        border-radius: 36px;
        border: 1px solid #fff;
        background: rgba(224, 224, 224, 0.2);
        box-shadow: 0px 4px 2px -2px rgba(255, 255, 255, 0.5) inset, 0px 3px 4px 0px #1f1f1f;
        backdrop-filter: blur(30px);
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    &__element {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        gap: 8px;
        margin-bottom: 32px;
    }
}

.element-number {
    text-align: center;
}

.element-desc {
    color: var(--text-text-color-text-inverted, #fff);

    font-family: 'DIN Next W1G Light';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    opacity: 0.9;
    margin-top: -4px;
}

.resource-overview-footer-container {
    width: 100%;
    position: relative;
}

.resource-overview-footer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;

    &__heading {
        padding: 14px 25px 14px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.resource-seperator-custom {
    height: 1px;
    width: 99.8%;
    background-color: rgba(255, 255, 255, 0.1);
}

.resource-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 99.8%;
}

.vertical-seperator-custom {
    height: 52px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
}

.vertical-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    width: 1px;
    height: 52px;
}

.milestone-mob-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.resource-overview-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
}

.bottom-shadow-body {
    width: 100%;
    opacity: 0.9;
    background: linear-gradient(0deg, #222 10%, rgba(34, 34, 34, 0.9) 25%, rgba(34, 34, 34, 0) 100%);
    height: 52px;
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: 1;
}

.bottom-shadow-footer {
    width: 52px;
    opacity: 0.9;
    background: linear-gradient(0deg, #222 10%, rgba(34, 34, 34, 0.9) 25%, rgba(34, 34, 34, 0) 100%);
    height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 1;
    rotate: 270deg;
    border-radius: 12px;
}

.resources-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 342px;
    height: 100%;
    color: #dfdedb;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    border-left: 1px solid #b8b8b0;
    box-shadow: 3px 3px 3px 0px #00000008 inset;
    opacity: 1;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 16px;

    &__sessions-box {
        height: 70%;
        min-height: 50%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &.open {
        opacity: 1;
        transform: translateX(0);
    }

    &__sources {
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        scrollbar-width: none;
        width: 100%;
        height: 30%;
        transition: height 0.7s ease-in-out;
        max-height: 50%;
        padding: 0 16px;

        &--wrapper {
            .links-wrapper {
                margin-bottom: 10px;

                .link {
                    display: inline-flex;
                    width: 100%;
                    padding: 6px 0;
                    color: var(--text-text-color-text-inverted, #fff);
                    font-family: 'DIN Next W1G';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 24px */
                    text-decoration-line: underline;
                    text-decoration-style: solid;
                    text-decoration-skip-ink: none;
                    text-decoration-thickness: auto;
                    text-underline-offset: auto;
                    opacity: 0.8;

                    a {
                        max-width: 100%;
                        color: $eth-white;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-decoration: underline !important;
                        margin-bottom: 12px;
                    }

                    .link-style {
                        color: var(--text-text-color-text-inverted, #fff);
                        font-family: 'DIN Next W1G';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%; /* 24px */
                        text-decoration-line: underline;
                        text-decoration-style: solid;
                        text-decoration-skip-ink: none;
                        text-decoration-thickness: auto;
                        text-underline-offset: auto;
                        text-underline-position: from-font;
                    }
                }
            }
        }

        &--title {
            border-bottom: 1px solid #bbbbb4;
        }

        .sources-gradient {
            height: 85px;
            background: linear-gradient(
                180deg,
                rgba(223, 222, 219, 0) 0%,
                rgba(223, 222, 219, 0.01) 11.79%,
                rgba(223, 222, 219, 0.03) 21.38%,
                rgba(223, 222, 219, 0.07) 29.12%,
                rgba(223, 222, 219, 0.12) 35.34%,
                rgba(223, 222, 219, 0.18) 40.37%,
                rgba(223, 222, 219, 0.25) 44.56%,
                rgba(223, 222, 219, 0.33) 48.24%,
                rgba(223, 222, 219, 0.41) 51.76%,
                rgba(223, 222, 219, 0.5) 55.44%,
                rgba(223, 222, 219, 0.59) 59.63%,
                rgba(223, 222, 219, 0.67) 64.66%,
                rgba(223, 222, 219, 0.76) 70.88%,
                rgba(223, 222, 219, 0.85) 78.62%,
                rgba(223, 222, 219, 0.93) 88.21%,
                #dfdedb 100%
            );
        }
    }
}

.file {
    border-radius: 12px;
    border: 1px solid #fff;
    background: rgba(245, 244, 243, 0.8);
    box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.3);
    backdrop-filter: blur(56px);
    padding: 10px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    display: block;

    &__extension {
        border-radius: 6px;
        border: 1px solid #000;
        background: $eth-black-light;
        box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
        text-align: center;
        padding: 12px 0;
        min-width: 48px;
        text-transform: uppercase;
        margin-right: 12px;
    }

    &__name {
        .eth-text {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            box-sizing: border-box;
        }
    }

    &__last-updated {
        font-size: 12px;
        line-height: 150%;
    }

    &__wrapper {
        width: 100%;
        overflow: hidden;
    }
}

.a-wrapper {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}
