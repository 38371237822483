$grid-breakpoints: (
  xs: 320px,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1600px,
);

// Desktop, XL+ sizes
$space-curve-pico: 0px;
$space-curve-nano: 8px;
$space-curve-micro: 12px;
$space-curve-mili: 16px;
$space-curve-centi: 20px;
$space-curve-deci: 24px;
$space-curve-hecto: 24px;
$space-curve-kilo: 32px;
$space-curve-mega: 40px;
$space-curve-giga: 48px;
$space-curve-tera: 64px;
$space-curve-peta: 80px;
$space-curve-exa: 96px;
$space-fixed-pico: 4px;
$space-fixed-nano: 8px;
$space-fixed-micro: 12px;
$space-fixed-mili: 16px;
$space-fixed-centi: 20px;
$space-fixed-deci: 24px;
$space-fixed-kilo: 32px;
$space-fixed-giga: 48px;

// Table, M/L sizes
@media screen and (max-width: 1024px) {
    $space-curve-pico: 0px;
    $space-curve-nano: 8px;
    $space-curve-micro: 12px;
    $space-curve-mili: 16px;
    $space-curve-centi: 20px;
    $space-curve-deci: 24px;
    $space-curve-hecto: 24px;
    $space-curve-kilo: 32px;
    $space-curve-mega: 40px;
    $space-curve-giga: 48px;
    $space-curve-tera: 64px;
    $space-curve-peta: 64px;
    $space-curve-exa: 80px;
    $space-fixed-pico: 4px;
    $space-fixed-nano: 8px;
    $space-fixed-micro: 12px;
    $space-fixed-mili: 16px;
    $space-fixed-centi: 20px;
    $space-fixed-deci: 24px;
    $space-fixed-kilo: 32px;
    $space-fixed-giga: 48px;
}

// Mobile, XS/S sizes
@media screen and (max-width: 768px) {
    $space-curve-pico: 0px;
    $space-curve-nano: 4px;
    $space-curve-micro: 8px;
    $space-curve-mili: 12px;
    $space-curve-centi: 12px;
    $space-curve-deci: 16px;
    $space-curve-hecto: 20px;
    $space-curve-kilo: 24px;
    $space-curve-mega: 24px;
    $space-curve-giga: 32px;
    $space-curve-tera: 48px;
    $space-curve-peta: 48px;
    $space-curve-exa: 64px;
    $space-fixed-pico: 4px;
    $space-fixed-nano: 8px;
    $space-fixed-micro: 12px;
    $space-fixed-mili: 16px;
    $space-fixed-centi: 20px;
    $space-fixed-deci: 24px;
    $space-fixed-kilo: 32px;
    $space-fixed-giga: 48px;
}

.eth-space-top-nano {
    padding-top: $space-curve-nano;
}

.eth-space-top-micro {
    padding-top: $space-curve-micro;
}

.eth-space-top-kilo {
    padding-top: $space-curve-kilo;
}

.eth-space-top-centi {
    padding-top: $space-curve-centi;
}

.eth-space-top-giga {
    padding-top: $space-curve-giga;
}

.eth-space-top-micro {
    padding-top: $space-curve-micro;
}

.eth-space-bottom-hecto {
    padding-bottom: $space-curve-hecto;
}

.eth-space-bottom-micro {
    padding-bottom: $space-curve-micro;
}

.eth-space-bottom-kilo {
    padding-bottom: $space-curve-kilo;
}
