$eth-blue: #215caf;
$eth-blue-light: #e9eff7;
$eth-blue-dark: #08407e;
$eth-petrol: #007894;
$eth-petrol-light: #e7f4f7;
$eth-petrol-dark: #00596d;
$eth-green: #627313;
$eth-green-light: #eff1e7;
$eth-green-dark: #365213;
$eth-bronze: #8e6713;
$eth-bronze-light: #f4f0e7;
$eth-bronze-dark: #704f12;
$eth-red: #b7352d;
$eth-red-light: #f8ebea;
$eth-red-dark: #96272d;
$eth-purple: #a30774;
$eth-purple-light: #f8e8f3;
$eth-purple-dark: #8c0a59;
$eth-grey: #6f6f6f;
$eth-grey-light: #f1f1f1;
$eth-grey-dark: #575757;
$eth-black: #000000;
$eth-white: #ffffff;
$eth-white-background-light: #f5f4f3;
$eth-white-background: #eeedea;
$eth-white-with-opacity: rgba(255, 255, 255, 0.92);
$eth-black-light: #222222;
$eth-black-light-20: rgba(34, 34, 34, 0.2);
$eth-black-light-31: rgba(34, 34, 34, 0.48);
$eth-black-light-60: rgba(0, 0, 0, 0.6);
$eth-black-light-80: rgba(0, 0, 0, 0.8);
$eth-alert-green: #2e7b32;
$eth-alert-orange: #fda726;
$eth-alert-red: #e2001a;
$eth-alert-red-light: #fef2f4;
$eth-alert-green-light: #f2f7f3;
$eth-alert-orange-light: #fff6e9;
$eth-grey-80: #8c8c8c;
$eth-grey-60: #a9a9a9;
$eth-grey-40: #c5c5c5;
$eth-grey-20: #e2e2e2;
$eth-purple-60: #ca6cae;
$eth-red-60: #d48681;
$eth-bronze-60: #bba471;
$eth-green-60: #a1ab71;
$eth-petrol-60: #66afc0;
$eth-blue-60: #7a9dcf;
$eth-alert-red-80: #ff3d53;
$eth-grey-90: #484848;
$eth-highlight-orange: #f19b00;
