@use '../../../../styles/colors.scss' as *;
@use '../../../../styles/typography' as *;

.leadership-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.first-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 450px) {
        top: 0;
        width: 100%;
    }
}

.features-title {
    display: none;
    font-size: 48px;
    text-align: center;
    line-height: 1.2;
    height: 100px;
    @media (max-width: 768px) {
        font-size: $font-size-h2;
    }
    @media (max-width: 500px) {
        line-height: 1.2;
    }

    .features-span {
        white-space: nowrap;
        padding: 0 5px;
        background-size: 200%;
        background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
    }
    .features-span-1 {
        margin-left: -5px;
    }
    .features-span-2 {
        margin-left: 5px;
    }
    .leadership-br {
        @media (max-width: 500px) {
            display: none;
        }
    }
}
// .feature-subtitle-first {
//     opacity: 0;
// }
// .feature-subtitle-second {
//     opacity: 0;
// }

.features-title-mobile {
    display: none;
    text-align: center;
    height: 100px;
    width: 90%;
    margin: auto;
    @media (max-width: 768px) {
        font-size: 26px;
    }
    @media (max-width: 500px) {
        line-height: 1.2;
    }
}

.features-span-1-mob {
    white-space: nowrap;
    padding: 0 5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.features-span-2-mob {
    white-space: nowrap;
    padding: 0 5px;
    margin-left: -5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.feature-subtitle-first-mob {
    opacity: 0;
    font-size: 32px;
    line-height: 45px;
}
.feature-subtitle-second-mob {
    opacity: 0;
    font-size: 32px;
    line-height: 45px;
}
