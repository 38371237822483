@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;
@use '../../styles/typography' as *;

.milestone-block-outer-wrapper {
    margin-bottom: 12px;
    background-color: $eth-black-light;
    border-radius: $border-radius-12;

    .milestone-block-inner-wrapper {
        display: flex;
        align-items: center;
        gap: $space-curve-centi;
        padding: $space-curve-centi;
        border-radius: $border-radius-12;
        border: 1px solid #00000080;
        box-shadow: 0px 1px 1px 0px #14141433;
        box-shadow: 0px 2px 1px 0px #ffffff59 inset;
        background: url('../../assets/img/confetti-bg.svg');
        background-position: right;
        background-size: auto;
        background-repeat: no-repeat;

        @media (max-width: 450px) {
            align-items: flex-start;
            flex-direction: column;
            background-position: -39px 4px;
            background-size: 112% auto;
        }

        .milestone-block-text-wrapper {
            display: flex;
            flex-direction: column;
            gap: $space-fixed-pico;

            .milestone-block-milestone-text {
                color: $eth-white;
                font-size: $font-size-l3;
                line-height: $font-line-height-l3;
                font-weight: $font-weight-bold;
                
                & ul {
                    padding-left: 14px;
                }
                
                & p, span {
                    font-size: $font-size-l3;
                    line-height: $font-line-height-l3;
                    font-weight: $font-weight-bold;
                    color: $eth-white;
                    margin-bottom: 0;
                }
            }

            .milestone-block-congratulation {
                font-size: $font-size-l3;
                line-height: $font-line-height-l3;
                font-weight: $font-weight-normal;
                color: $eth-white;
                opacity: 0.8;
                
                & p, span {
                    font-size: $font-size-l3;
                    line-height: $font-line-height-l3;
                    font-weight: $font-weight-normal;
                    color: $eth-white;
                    opacity: 0.8;
                }
            }
        }
    }
}
