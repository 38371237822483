@use '../../styles/colors' as *;
@use '../../styles/components' as *;
@use '../../styles/spacing' as *;

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 77px);

    .login-container {
        padding: $space-curve-kilo;
        width: 543px;
        background-color: $eth-white;
        border-radius: $border-radius-12;
        text-align: center;
        box-shadow: 0px 3px 4px 0px #887E644D;

        .login-title-wrapper {
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-curve-hecto;

            .login-title {
                text-align: left;
                font-size: 28px;
                font-weight: 400;
                line-height: 33.6px;
            }
        }

        .login-error {
            margin-top: 10px;
            color: $eth-red;
            font-size: 14px;
        }
        

        & button {
            display: block;
            width: 100%;
        }
    }
}
