@use '../../../styles/spacing' as *;

.session-summary {
    margin-top: $space-curve-hecto;

    &__wrapper {
        border-radius: var(--border-border-radius-border-radius-12, 12px);
        border: 1px solid #B8B8B0;
        background: linear-gradient(180deg, rgba(214, 212, 205, 0.15) 0%, rgba(254, 254, 254, 0.03) 27.5%, rgba(250, 249, 249, 0.15) 100%);
        box-shadow: 0px 1px 0px 0px #FFF;
        backdrop-filter: blur(8px);
        padding: 32px;
    }
}
