.session-overview {
    position: fixed;
    left: 50%;
    bottom: 10px;
    width: 95%;
    background-color: #222222;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 3px 1px -1px rgba(255, 255, 255, 0.35) inset,
        0px 1px 0 0px #000 inset;
    display: none;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 99;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: translateX(-50%);
    color: white;
    padding-top: 16px;
    overflow: hidden;
    @media (max-width: 450px) {
        display: flex;
    }
}

.session-overview-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    padding: 0 16px;
    margin-bottom: 16px;
}

.session-overview-container {
    position: relative;
    width: 100%;
}

.session-overview-body {
    overflow: auto;
    height: 252px;
    padding: 16px 16px 0 16px;
    width: 100%;

    &__number {
        position: relative;
        width: 26px;
        height: 26px;
    }
    &__ring {
        position: absolute;
        border-radius: 36px;
        border: 1px solid #fff;
        background: rgba(224, 224, 224, 0.2);
        box-shadow: 0px 4px 2px -2px rgba(255, 255, 255, 0.5) inset, 0px 3px 4px 0px #1f1f1f;
        backdrop-filter: blur(30px);
        width: 26px;
        height: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    &__element {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        gap: 8px;
        margin-bottom: 32px;
    }
}

.element-number {
    text-align: center;
}

.element-desc {
    color: var(--text-text-color-text-inverted, #fff);

    font-family: 'DIN Next W1G Light';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    opacity: 0.9;
    margin-top: -4px;
}

.session-overview-footer-container {
    width: 100%;
    position: relative;
}

.session-overview-footer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;

    &__heading {
        padding: 14px 25px 14px;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.milestone-seperator-custom {
    height: 1px;
    width: 99.8%;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 2;
}

.milestone-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 99.8%;
    z-index: 2;
}

.vertical-seperator-custom {
    height: 52px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
}

.vertical-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    width: 1px;
    height: 52px;
}

.milestone-mob-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.session-overview-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
}

.bottom-shadow-body {
    width: 100%;
    opacity: 0.9;
    background: linear-gradient(0deg, #222 10%, rgba(34, 34, 34, 0.9) 25%, rgba(34, 34, 34, 0) 100%);
    height: 52px;
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: 1;
}

.bottom-shadow-footer {
    width: 52px;
    opacity: 0.9;
    background: linear-gradient(0deg, #222 10%, rgba(34, 34, 34, 0.9) 25%, rgba(34, 34, 34, 0) 100%);
    height: 100%;
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 1;
    rotate: 270deg;
    border-radius: 12px;
}
