.thankyou-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.thankyou-modal {
    border-radius: 12px;
    border: 2px solid #fff;
    background: rgba(245, 244, 243, 0.9);
    box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 4px 0px rgba(117, 117, 117, 0.3);
    backdrop-filter: blur(4px);
    padding: 20px;
    width: 454px;
}

.thankyou-close {
    margin-left: 16px;
    cursor: pointer;
}

.thankyou-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.thankyou-close-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thankyou-seperator {
    background: var(--border-border-colour-border-separator, #f1f1f1);
    height: 1px;
    margin-bottom: 12px;
}

.thankyou-body {
    margin-bottom: 18px;
    color: var(--text-text-color-text-normal, #000);

    /* 16-Regular */
    font-family: 'DIN Next W1G Light';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.thankyou-footer {
    width: 100%;
    border-radius: 2px;
    background: var(--Colours-Base-Colors-color-black-light, #222);
    display: flex;
    padding: 14px 32px 13px 32px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: var(--Colours-Base-Colors-color-white, #fff);

    /* 16 Medium Interaction */
    font-family: 'DIN Next W1G';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    cursor: pointer;
}

.thankyou-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thankyou-text-heading {
    color: var(--text-text-color-text-normal, #000);

    /* 20-Bold-Titel */
    font-family: 'DIN Next W1G';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    margin-left: 8px;
}

.white-cross-icon {
    width: 19px;
    height: 19px;
    margin-right: 8px;
}

.margin-top {
    margin-top: -3px;
}
