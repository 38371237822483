@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


$theme-colors: (
        "primary": #000000,
        "danger": #ff4136
);

$colors: (
        "gray-light": #F7F7F9,
);

@each $color-name, $color-value in $colors {
    .bg-#{$color-name} {
        background-color: $color-value;
    }
}


@import '~bootstrap/scss/bootstrap.scss';
