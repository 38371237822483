@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;
@use '../../styles/typography' as *;

.privacy-policy-modal-wrapper {
    position: relative;
    max-width: 720px;
    padding: $space-curve-centi;
    display: flex;
    flex-direction: column;
    gap: $space-curve-centi;
    border-radius: $border-radius-12;
    background: $eth-black-light;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        border: 1px solid #000;
        background-color: #000;
        border-radius: 12px;
        z-index: -1;
        width: 100%;
        box-sizing: content-box;
        height: 100%;
        transform: translate(-50%, -50%);
    }
    &::after {
        content: '';
        position: absolute;
        top: 49.7%;
        left: 50%;
        right: 0;
        bottom: 0;
        background-color: transparent;
        border-radius: 12px;
        z-index: 1;
        width: 100.2%;
        box-sizing: content-box;
        height: 100%;
        pointer-events: none;
        transform: translate(-50%, -50%);
        box-shadow: 0px 1px 1px 0px rgba(20, 20, 20, 0.2), 0px 2px 1px 0px rgba(255, 255, 255, 0.3490196078) inset,
            0px 1px 0 0px #000 inset;
    }

    .privacy-policy-modal-text {
        display: flex;
        flex-direction: column;
        gap: $space-curve-nano;
        color: $eth-white;

        .privacy-policy-modal-title {
            font-weight: $font-weight-bold;
        }

        .privacy-policy-modal-text {
            opacity: 0.8;
        }
    }

    .privacy-policy-modal-check-wrapper {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: $space-curve-nano;
        color: $eth-white;
    }

    .privacy-policy-modal-error {
        padding: 2px 8px;
        background: $eth-red;
        border-radius: 2px;
        color: $eth-white;
    }

    .privacy-policy-modal-button-wrapper {
        display: flex;
        justify-content: end;
        gap: $space-curve-nano;
    }
}

.privacy-text {
    font-size: 14px !important;
    margin-left: 10px;
}

.privacy-icon {
    margin-top: -1px;
    margin-left: 3px;
}
