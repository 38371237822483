@use '../../../styles/spacing' as *;

.session-header {
    padding: 32px 0;
    position: relative;
    z-index: 1;
    background: linear-gradient(
        0deg,
        rgba(238, 237, 234, 0) 0%,
        rgba(238, 237, 234, 0.8) 25.02%,
        rgba(238, 237, 234, 0.98) 50.2%,
        rgba(238, 237, 234, 0.95) 77.84%
    );

    @media (max-width: 450px) {
        display: none;
    }

    &__container {
        height: auto;

        @media (max-width: 1280px) {
            max-width: calc(100% - 350px) !important;
            padding: 0 0 0 24px !important;
        }

        &.right-menu-active {
            @media (max-width: 1510px) {
                max-width: calc(100% - 700px) !important;
            }

            @media (max-width: 1400px) {
                max-width: calc(100% - 560px) !important;
            }

            @media (max-width: 1700px) {
                padding: 0 24px !important;
            }

            @media (min-width: 1510px) and (max-width: 1870px) {
                &.left-menu-active {
                    max-width: calc(100% - 700px) !important;
                }
            }
        }

        @media (max-width: 1600px) {
            &.left-menu-active {
                max-width: 782px;
            }
        }

        @media (max-width: 1420px) {
            &.left-menu-active {
                max-width: calc(100% - 400px) !important;
            }
        }
    }

    &__menu {
        border-radius: var(--border-border-radius-border-radius-12, 12px);
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        background: var(--surface-surface-primary, #222);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
        top: 38px;
        right: 14px;
        align-items: center;
        width: 266px;

        & span {
            line-height: 130%;
        }

        & > div:hover {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    &__menu-mob {
        border-radius: var(--border-border-radius-border-radius-12, 12px);
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        background: var(--surface-surface-primary, #222);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35), 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
        top: 55px;
        right: 8px;
        align-items: center;
        width: 266px;

        & span {
            line-height: 130%;
        }

        & > div:hover {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    &__title {
        width: calc(100% - 24px);
        overflow: hidden;
        background: linear-gradient(90deg, #222 90.01%, rgba(255, 255, 255, 0) 98.46%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
        font-weight: 400;
        line-height: 120%;
        gap: $space-curve-micro;
        white-space: nowrap;
        @media (max-width: 450px) {
            display: none;
        }
    }

    &__icon {
        right: 0;
        border-radius: 36px;
        border: 2px solid #fff;
        opacity: 0.8;
        background: #e0e0e0;
        box-shadow: 0px 1px 1px 0px #fff inset, 0px 3px 4px 0px #c6c3bb;
        backdrop-filter: blur(30px);
        width: 32px;
        height: 32px;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 450px) {
            display: none;
        }
    }

    &__mob-header {
        display: none;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        z-index: 3;
        border-radius: 4px;
        opacity: 0.95;
        background-image: url('../../../assets/backgrounds/mob-top-blur.png');
        background-size: 100% 100%;
        background-position: bottom;
        padding: 0 8px;
        @media (max-width: 450px) {
            display: flex;
        }
    }

    &__mob-title {
        position: relative;
        width: 100%;
        text-align: start;
        display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'DIN Next W1G Light';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        white-space: nowrap;
        @media (max-width: 450px) {
            display: inline-block;
            margin-left: 50px;
        }
        & > div {
            background-image: linear-gradient(to right, black 90%, rgba(224, 224, 224, 0) 95%, rgba(224, 224, 224, 0) 0%);
            background-clip: text;
            background-size: 100%;
            -webkit-text-fill-color: transparent;
        }
    }

    &__mob-title-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__mob-shadow {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to left, #e9e8e533, transparent);
        filter: blur(40);
    }

    &__mob-icon {
        right: 0;
        border-radius: 36px;
        border: 2px solid #fff;
        opacity: 0.8;
        background: #e0e0e0;
        box-shadow: 0px 1px 1px 0px #fff inset, 0px 3px 4px 0px #c6c3bb;
        backdrop-filter: blur(30px);
        padding: 4px;
        width: 32px;
        height: 32px;
        display: none;
        @media (max-width: 450px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__mob-icon-dark {
        right: 0;
        backdrop-filter: blur(30px);
        padding: 4px;
        width: 32px;
        height: 32px;
        display: none;
        border-radius: 30px;
        border: 1px solid #000;
        background: var(--surface-surface-primary, #222);
        box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
        @media (max-width: 450px) {
            display: inline-block;
        }
    }

    &__mob-icon-container {
        display: none;
        @media (max-width: 450px) {
            display: inline-block;
        }
    }
    &__mob-menu-container {
        display: none;
        @media (max-width: 450px) {
            display: inline-block;
            width: 32px;
            height: 32px;
        }
    }
}

.menu_bars-icon {
    width: 32px;
    height: 32px;
}

.headers-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 99.8%;
}

.headers-seperator-custom {
    height: 1px;
    width: 99.8%;
    background-color: rgba(255, 255, 255, 0.1);
}

.icon-dark {
    right: 0;
    backdrop-filter: blur(30px);
    padding: 4px;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    border: 1px solid #000;
    background: var(--surface-surface-primary, #222);
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.35) inset;
}

.session-hover-button {
    height: 44px;
    width: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: start;
}

.help-icons-dark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s opacity ease-in-out;
}

.help-visible {
    opacity: 1;
}

.help-invisible {
    opacity: 0;
}

.desktop-help-container {
    position: relative;
}

.delete-icon {
    margin-top: -3.6px;
}
