.header-illustration-morning {
    position: absolute;
    top: -260px;
    height: 330px;

    @media (max-width: 1800px) {
        left: -50px;

        &.left-menu-active {
            left: -100px;
        }
    }

    @media (max-width: 1700px) {
        left: -100px;

        &.left-menu-active {
            left: -150px;
        }
    }

    @media (max-width: 1350px) {
        left: -150px;

        &.left-menu-active {
            left: -200px;
        }
    }

    @media (max-width: 450px) {
        left: -350px;

        &.left-menu-active {
            left: -450px;
        }
    }

    .header-illustration-1 {
        border-radius: 415.916px;
        background: linear-gradient(222deg, #ff845c 19.65%, #f03800 81.59%);
        filter: blur(110px);
        width: 728.042px;
        height: 276.025px;
        left: 120px;
        transform:  translate3d(0, 0, 0);
        @media (max-width: 450px) {
            width: 500px;
            height: 300px;
        }
    }

    .header-illustration-2 {
        border-radius: 415.916px;
        background: linear-gradient(222deg, #ffebaa 19.65%, #f9c483 81.59%);
        filter: blur(110px);
        transform: translate3d(0, 0, 0);
        width: 728.042px;
        height: 276.025px;
        left: 353px;
        bottom: 0;
        @media (max-width: 450px) {
            width: 500px;
            height: 300px;
        }
    }

    &.small {
        .header-illustration-1 {
            width: 415.916px;
            height: 165.615px;
            left: 98px;
        }

        .header-illustration-2 {
            width: 415.916px;
            height: 165.615px;
            left: 231px;
        }
    }
}

.header-illustration-evening {
    position: absolute;
    top: -216px;
    height: 389px;
    left: 120px;

    @media (max-width: 2300px) {
        left: -20px;

        &.left-menu-active {
            left: -70px;
        }
    }

    @media (max-width: 1700px) {
        left: -40px;

        &.left-menu-active {
            left: -90px;
        }
    }

    @media (max-width: 1700px) {
        left: -70px;

        &.left-menu-active {
            left: -120px;
        }
    }

    @media (max-width: 1350px) {
        left: -120px;

        &.left-menu-active {
            left: -170px;
        }
    }

    @media (max-width: 450px) {
        left: -400px;

        &.left-menu-active {
            left: -550px;
        }
    }

    .header-illustration-1 {
        border-radius: 301.827px;
        background: #7ba1d7;
        filter: blur(82px);
        width: 259.014px;
        height: 301.827px;
        transform: rotate(-31.189deg) translate3d(0, 0, 0);
        flex-shrink: 0;
        left: 120px;

        @media (max-width: 450px) {
            width: 350px;
            height: 50px;
        }
    }

    .header-illustration-2 {
        border-radius: 301.827px;
        background: #987bd7;
        filter: blur(82px);
        width: 171.908px;
        height: 301.827px;
        transform: rotate(-31.189deg) translate3d(0, 0, 0);
        flex-shrink: 0;
        left: 327px;
        bottom: 0;

        @media (max-width: 450px) {
            width: 350px;
            height: 50px;
        }
    }

    .header-illustration-3 {
        border-radius: 327.652px;
        background: #4b7ff0;
        filter: blur(82px);
        width: 171.908px;
        height: 327.652px;
        transform: rotate(-31.189deg) translate3d(0, 0, 0);
        flex-shrink: 0;
        left: 457px;
        bottom: 0;
    }

    .header-illustration-4 {
        border-radius: 351.788px;
        background: #1993e4;
        filter: blur(82px);
        width: 157.812px;
        height: 351.788px;
        transform: rotate(-31.189deg) translate3d(0, 0, 0);
        flex-shrink: 0;
        left: 526px;
        top: -20px;
    }

    .header-illustration-5 {
        border-radius: 314.74px;
        background: #7bc0d7;
        filter: blur(82px);
        width: 171.908px;
        height: 314.74px;
        transform: rotate(-31.189deg) translate3d(0, 0, 0);
        flex-shrink: 0;
        left: 698px;
        bottom: 20px;
    }

    .header-illustration-6 {
        width: 171.908px;
        height: 314.74px;
        transform: rotate(-31.189deg) translate3d(0, 0, 0);
        flex-shrink: 0;
        border-radius: 365.605px;
        background: #5f9bdf;
        filter: blur(82px);
        left: 791px;
    }

    &.small {
        .header-illustration-1 {
            width: 150px;
            height: 250px;
            left: 98px;
        }

        .header-illustration-2 {
            width: 150px;
            height: 250px;
            left: 240px;
            bottom: unset;
            top: 60px;
        }

        .header-illustration-3 {
            width: 90px;
            height: 250px;
            left: 280px;
            bottom: unset;
            top: 60px;
        }

        .header-illustration-4 {
            width: 80px;
            height: 250px;
            left: 320px;
        }

        .header-illustration-5 {
            width: 90px;
            height: 250px;
            left: 400px;
        }

        .header-illustration-6 {
            width: 90px;
            height: 250px;
            left: 500px;
        }
    }
}

.header-text-first {
    @media (max-width: 450px) {
        color: #000;
        font-family: 'DIN Next W1G';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
}
.header-text-second {
    @media (max-width: 450px) {
        color: #000;
        font-family: 'DIN Next W1G';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
}
