@use '../../styles/colors' as *;
@use '../../styles/components' as *;
@use '../../styles/typography' as *;

.tooltip-wrapper {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateX(-20%) translateY(-50%);
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $eth-black-light;
    padding: 6px 12px;
    border-radius: $border-radius-12;
    white-space: nowrap;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    transition: transform 0.5s ease, opacity 0.5s ease;

    &.top {
        transform: translateX(-50%) translateY(-20px);
    }

    &.inactive {
        background-color: $eth-grey-40;
        border: 1px solid #B4B4B4;
        box-shadow: 0px 1px 2px 0px #00000012;
    }

    .tooltip-icon {
        width: 24px;
        height: 24px;
    }

    .tooltip-text {
        font-size: $font-size-l3;
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        color: $eth-white;
    }

    .tooltip-key {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        clip-path: inset(0 0 0 0 round 4px);
        background: conic-gradient(
            from 320deg,
            #b8b8b8 0deg,
            #8b8b8b 90deg,
            #616161 180deg,
            #8b8b8b 270deg,
            #b8b8b8 360deg
        );

        .tooltip-key-mask {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $eth-black-light;
            width: 22px;
            height: 22px;
            border-radius: $border-radius-3;

            .tooltip-key-text {
                width: 100%;
                height: 100%;
                background: #5F5F5F;
                border-radius: $border-radius-3;
                color: $eth-white;
                opacity: 0.7;
                font-size: $font-size-l3;
                font-weight: 700;
                text-align: center;
                overflow: hidden;
            }
        }
    }
}
