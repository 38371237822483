@use './spacing' as *;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    max-width: 872px !important;
    padding: 0 !important;
}

@media screen and (max-width: 1600px) {
    .container {
        max-width: 782px !important;
        padding: 0 !important;
    }
}

@media screen and (max-width: 1440px) {
    .container {
        max-width: 760px !important;
        padding: 0 !important;
    }
}

@media screen and (max-width: 1280px) {
    .container {
        max-width: 100% !important;
        padding: $space-curve-kilo $space-curve-deci !important;
    }
}

@media screen and (max-width: 450px) {
    .container {
        max-width: 100% !important;
        padding: 0 !important;
    }
}
