@use '../../styles/colors' as *;

.eth-base-layout {
    position: relative;
    display: grid;
    grid-template-columns: 272px 1fr auto;
    min-height: 100vh;
    border: 12px solid $eth-black-light;
    clip-path: inset(12px round 2px);
    transition: grid-template-columns 0.3s ease;
    overflow: hidden;

    @media (max-width: 450px) {
        border: 0;
        clip-path: inset(0 round 0);
    }

    &.collapsed {
        grid-template-columns: 0 1fr auto;

        .footer-container {
            margin-left: 0;
        }

        & .left-menu {
            & .menu-content {
                opacity: 1;
                visibility: visible;
                margin-left: 0px;
                margin-right: 0px;
                transition: margin-left 0.3s ease, margin-right 0.3s ease;
            }

            &.collapsed .menu-content {
                margin-left: -272px;
                margin-right: 272px;
            }

            @keyframes fadeOut {
                from {
                    margin-left: 0px;
                    margin-right: 0px;
                }
                to {
                    margin-left: -272px;
                    margin-right: 272px;
                }
            }

            & .segment-container {
                left: 0px;
            }
        }
    }
}

.eth-base-layout-content {
    grid-template-rows: 1fr;
    background: $eth-white-background url('../../assets/img/noise--pattern.png') repeat 0% 0%;

    @media (max-width: 450px) {
        background-image: url('../../assets/backgrounds/chat-bg-mob.png');
    }

    &__background {
        background-image: url('../../assets/img/eth-background.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 450px) {
            background-image: url('../../assets/img/eth-background-mob.png');
        }
    }
}
