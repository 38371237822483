@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;

@keyframes waveAnimation {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-fixed-nano;
    height: calc(100vh - 125px);
    text-align: center;

    .loader-icon {
        position: relative;
        width: 48px;
        height: 48px;
        overflow: hidden;

        svg {
            position: absolute;
            top: -428px;
            left: -576px;
            width: 1200px !important;
            height: 900px !important;
            transform: scale(0.3) !important;
        }
    }

    .loader-text {
        position: relative;
        margin-top: 5px;
        color: #9C9483;
        background: -webkit-gradient(linear, left top, right top, from(#F7F5EE), to(#F7F5EE), color-stop(0.5,#11100F));
        background-size: 30px 100%;
        background-clip: text;
        animation: waveAnimation 2s infinite;
        background-repeat: no-repeat;
        background-position: 0 0;
    }
}

