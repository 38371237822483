@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;
@use '../../styles/typography' as *;

.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  
    input[type="checkbox"] {
        display: none;
    }
  
    .checkbox-mark {
        width: 24px;
        height: 24px;
        background: url('../../assets/icons/check-box-outline-blank.svg') center center no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        margin-right: 8px;
    }
  
    input[type="checkbox"]:checked + .checkbox-mark {
        background: url('../../assets/icons/check-box.svg') center center no-repeat;
    }
  
    .checkbox-label {
        font-weight: $font-weight-semi-bold;
        line-height: 1rem;
        color: $eth-white;
        height: 14px;
    }
  }