@use '../../../styles/colors' as *;
@use '../../../styles/components' as *;
@use '../../../styles/typography' as *;

.speech-mode-outer-wrapper {
    position: relative;
    padding: 20.5px 24px;
    background: $eth-black-light;
    border: 1px solid #00000080;
    box-shadow: 0px 1px 1px 0px #14141433, 0px 2px 1px 0px #FFFFFF59 inset;
    width: 100%;
    border-radius: 12px;

    .speech-mode-shadow {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        height: 24px;
        border-radius: $border-radius-12;
        box-shadow: 0px 2px 1px 0px #FFFFFF59 inset;
    }

    .speech-mode-inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $eth-white;

        .speech-mode-timer {
            margin-right: 14px;
        }


        .speech-wave-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 3px;
            width: 639px;
            height: 69px;
            overflow: hidden;
            padding: 0 41px;
            
            @media (max-width: 1000px) {
            width: 400px;
            }
          
            @media (max-width: 750px) {
            width: 300px;
            }
           
            @media (max-width: 650px) {
            width: 100px;
            }
           
            @media (max-width: 450px) {
                display: none;
            }
        
            .speech-wave-mask {
                position: absolute;
                width: 28%;
                height: 100%;
                
        
                &.left {
                    left: 0;
                    top: 0;
                    background: linear-gradient(90deg, #222222 0%, rgba(34, 34, 34, 0.991615) 11.79%, rgba(34, 34, 34, 0.967585) 21.38%, rgba(34, 34, 34, 0.9296) 29.12%, rgba(34, 34, 34, 0.879348) 35.34%, rgba(34, 34, 34, 0.818519) 40.37%, rgba(34, 34, 34, 0.7488) 44.56%, rgba(34, 34, 34, 0.671881) 48.24%, rgba(34, 34, 34, 0.589452) 51.76%, rgba(34, 34, 34, 0.5032) 55.44%, rgba(34, 34, 34, 0.414815) 59.63%, rgba(34, 34, 34, 0.325985) 64.66%, rgba(34, 34, 34, 0.2384) 70.88%, rgba(34, 34, 34, 0.153748) 78.62%, rgba(34, 34, 34, 0.0737185) 88.21%, rgba(34, 34, 34, 0) 100%);
                    z-index: 1;
                }
        
                &.right {
                    right: 0;
                    top: 0;
                    background: linear-gradient(270deg, #222222 0%, rgba(34, 34, 34, 0.991615) 11.79%, rgba(34, 34, 34, 0.967585) 21.38%, rgba(34, 34, 34, 0.9296) 29.12%, rgba(34, 34, 34, 0.879348) 35.34%, rgba(34, 34, 34, 0.818519) 40.37%, rgba(34, 34, 34, 0.7488) 44.56%, rgba(34, 34, 34, 0.671881) 48.24%, rgba(34, 34, 34, 0.589452) 51.76%, rgba(34, 34, 34, 0.5032) 55.44%, rgba(34, 34, 34, 0.414815) 59.63%, rgba(34, 34, 34, 0.325985) 64.66%, rgba(34, 34, 34, 0.2384) 70.88%, rgba(34, 34, 34, 0.153748) 78.62%, rgba(34, 34, 34, 0.0737185) 88.21%, rgba(34, 34, 34, 0) 100%);
                }
            }
        
            .speech-wave-line {
                width: 3px;
                min-height: 4px;
                background-color: #ffffff;
                border-radius: 12px;
            }
        }
    }
}