@use '../../styles/components' as *;
@use '../../styles/colors' as *;
@use '../../styles/spacing' as *;

@keyframes waveAnimation {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.text-input-outer-wrapper {
    position: relative;
    padding: 0;
    border-radius: $border-radius-12;
    height: fit-content;

    &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: 20px;
        right: 20px;
        bottom: 8px;
        border-radius: inherit;
        background: conic-gradient(
            from 55.21deg at 45.32% 60.21%,
            #225caf 12deg,
            #ae342d 69.89deg,
            #b28013 215.46deg,
            #bc5746 1deg,
            #cc1896 254.81deg,
            #1fb51d 1deg,
            #225caf 440deg
        );
        filter: blur(56px);
        opacity: 0.15;
        transition: opacity 0.3s ease-in-out;
        transform: translate3d(0, 0, 0);
    }

    &:hover {
        &::before {
            opacity: 0.45;
        }
    }

    &.milestone {
        &::before {
            display: none;
        }

        @media (max-width: 450px) {
            border-radius: 12px 12px 0 0;
            padding: 0;
        }
    }

    .text-input-wrapper {
        position: relative;
        border: 2px solid $eth-white;
        padding: 12px 13px 9px 15px;
        background-color: rgba(245, 244, 243, 0.75);
        box-shadow: 0px 3px 4px 0px #887e644d;

        .text-input-shadow {
            position: absolute;
            left: -2px;
            right: -2px;
            top: -2px;
            border-radius: 12px;
            box-shadow: 0px 4px 2px 0px rgba(245, 244, 243, 0.75) inset;
            height: 24px;
        }

        .text-input {
            max-height: 184px;
            padding-right: 110px !important;
            border-radius: 2px;
            border: 1px solid rgba(103, 103, 103, 0.6);
            padding: 22px 12px 18px 18px;
            background: #f7f5ee;
            box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.9), 0px 3.5px 2px 0px rgba(255, 255, 255, 0.9) inset;
            resize: none;
            outline: none;

            @media (max-width: 450px) {
                padding-right: 70px !important;
            }
            &::-webkit-scrollbar {
                background: transparent;
            }

            &::-webkit-scrollbar-button {
                display: none;
            }

            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-radius: 20px;
                box-shadow: inset 0 0 10px 10px #00000050;
                border: solid 3px transparent;
            }

            &:focus {
                box-shadow: 0px 4px 3px 0px #dddad3 inset;
                border: 1px solid rgba(103, 103, 103, 1);
            }

            &__microphone {
                right: 30px;
                bottom: 31px;
            }
        }

        &.processing {
            position: relative;

            .text-input-processing-placeholder {
                position: absolute;
                top: calc(50% - 24px);
                left: 28px;
                display: flex;
                align-items: center;
                gap: 8px;
                overflow: hidden;

                .placeholder-icon {
                    position: relative;
                    width: 48px;
                    height: 48px;
                    overflow: hidden;

                    svg {
                        position: absolute;
                        top: -428px;
                        left: -576px;
                        // transform: translate(-50%, -50%);
                        width: 1200px !important;
                        height: 900px !important;
                        transform: scale(0.3) !important;
                    }
                }

                .placeholder-text {
                    position: relative;
                    margin-top: 5px;
                    color: #9c9483;
                    background: -webkit-gradient(
                        linear,
                        left top,
                        right top,
                        from(#f7f5ee),
                        to(#f7f5ee),
                        color-stop(0.5, #11100f)
                    );
                    background-size: 30px 100%;
                    background-clip: text;
                    animation: waveAnimation 2s infinite;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                }
            }
        }
    }
}

.text-input-background {
    opacity: 0.15;
    background: conic-gradient(
        from 51deg at 29.32% 55.21%,
        #ae342d 65.8854335546deg,
        #b28013 130.4645884037deg,
        #bc5746 186.9745802879deg,
        #cc1896 254.8074102402deg,
        #869e1a 325.3497219086deg,
        #225caf 360deg
    );
    filter: blur(28px);
    position: absolute;
    width: 100%;
    height: 100%;
}

.desktop-view {
    display: flex;
    @media (max-width: 450px) {
        display: none;
    }
}

.mob-view {
    display: none;
    @media (max-width: 450px) {
        display: flex;
    }
}

.top-border-only {
    border-radius: $border-radius-12 $border-radius-12 0 0;
}

.all-border {
    border-radius: $border-radius-12;
}

.border-for-chat {
    border-radius: $border-radius-12 !important;
    @media (max-width: 450px) {
        border-radius: $border-radius-12 $border-radius-12 0 0 !important;
    }
}
